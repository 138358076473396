import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import * as message from "../components/toastr";
import AvaliacaoAulaCursoService from "../app/service/avaliacaoAulaCursoService";

export function Notas(props) {
  const rows = [];
  for (var i = 0; i < 11; i++) {
    let idbt = `btnNotaAula${i}`;
    rows.push(
      <div key={i} className="ms-2">
        <input
          type="radio"
          className="btn-check"
          name="btnNota"
          id={idbt}
          onClick={() => {
            props.notaSelecionada();
          }}
          autoComplete="off"
        />
        <label className="btn btn-outline-primary" htmlFor={idbt}>
          {i}
        </label>
      </div>
    );
  }
  return rows;
}

export default function AvaliacaoAulaCurso(props) {
  const [motivo, setMotivo] = useState("");
  const [notaSelecionada, setnotaSelecionada] = useState(false);
  const [exibirModal, setExibirModal] = useState(false);

  useEffect(() => {
    if (props.avaliar === true) {
      var curso = props.curso;

     

      const service = new AvaliacaoAulaCursoService();

      service
        .cursoJaAvaliado(curso.id, curso.aulaAtual)
        .then((response) => {
          if (!response.data) {
            setExibirModal(true);
          } else {
            setExibirModal(false);
          }
        })
        .catch((erro) => {
          setExibirModal(false);
          console.error(erro);
        });
    }
  }, [props.curso, props.avaliar]);

  const enviar = () => {
    var nota = pegarNotaSelecionada();

    if (nota === null) {
      message.mensagemAlert("Nota obrigatória");
    } else {
      if (nota <= 6 && motivo.length < 8) {
        message.mensagemAlert("O que te motivou nesta nota?");
      } else {
        var dto = { nota: nota, comentario: motivo };
        const service = new AvaliacaoAulaCursoService();
        service
          .gravarNota(dto, props.curso.id, props.curso.aulaAtual)
          .then(() => {
            setExibirModal(false);
          })
          .catch((erro) => {
            console.error("Erro ao avaliar aula! ", erro);
            setExibirModal(false);
          });
      }
    }
  };

  const pegarNotaSelecionada = () => {
    for (var i = 0; i < 11; i++) {
      let idbt = `btnNotaAula${i}`;
      if (document.getElementById(idbt).checked) {
        return i;
      }
    }

    return null;
  };

  return (
    <>
      <Modal
        show={exibirModal}
        onHide={() => {
          setExibirModal(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="notaota">
              <Form.Label>
                <b>Em uma escala de 0 a 10, o quanto você gostou desta aula?</b>
              </Form.Label>
              <div
                className="btn-group btn-group-lg mb-2 ml-3 d-flex justify-content-center"
                role="group"
                aria-label="Large button group"
              >
                <Notas
                  notaSelecionada={() => {
                    setnotaSelecionada(true);
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="motivoNota">
              <Form.Label>O que te motivou nesta nota?</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="R..."
                maxLength={400}
                value={motivo}
                onChange={(e) => {
                  setMotivo(e.target.value);
                }}
              />
            </Form.Group>
            <Button
              disabled={!notaSelecionada}
              onClick={() => {
                enviar();
              }}
            >
              Enviar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
